@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
  padding: 0px;
  background-color: rgba(244,236,230,1);
}

.anton-regular {
  font-family: "Anton", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "joystix";
  src: url("./assets/fonts/joystix.ttf");
}

.text-outline {
  -webkit-text-stroke: var(--outline-width) var(--outline-color);
  /* text-stroke: var(--outline-width) var(--outline-color); */
}

.share-link-span{
  display: inline-block;
  padding: 12px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #e9e7e7;
}

.share-link-copy-btn{
  display: inline-block;
  padding: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  background-color: #dad8d8;
}